import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout/index.js";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Wide from "../components-styled/Wide";
import PictureSet from "../components/PictureSet";
import imgSet06 from "../imgsets/imgset06";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Infos" keywords={[]} mdxType="SEO" />
    <Wide mdxType="Wide">
      <PictureSet imgSet={imgSet06} mdxType="PictureSet" />
    </Wide>
    <h1>{`Infos`}</h1>
    <h2>{`Über das hngc`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.rheinzeiger.de/das-hochschulgruendernetz-cologne-e-v-hgnc/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Rheinzeiger, 3.4.2020: hochschulgründernetz cologne e.V. (hgnc)`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://www.rheinzeiger.de/hochschulgruendernetz-cologne-e-v-hgnc/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Rheinzeiger, 18.10.2019: hochschulgründernetz cologne e.V. (hgnc)`}</a></p>
    <h2>{`Wettbewerbe`}</h2>
    <p><a parentName="p" {...{
        "href": "https://rhive.de/tickets/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`https://rhive.de/tickets/`}</a></p>
    <h2>{`Gründernetzwerke`}</h2>
    <p>{`TODO: Warum sind wir nicht zertifiziert?`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.gruenderstipendium.nrw/gruendungsnetzwerke",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`https://www.gruenderstipendium.nrw/gruendungsnetzwerke`}</a></p>
    <h2>{`Fördergelder`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.exist.de/DE/Programm/Exist-Gruenderstipendium/inhalt.html",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Das EXIST-Gründerstipendium`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.gruenderstipendium.nrw/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Förderstipendium NRW`}</a></p>
    <p>{`TODO`}</p>
    <h2>{`Raumangebote`}</h2>
    <h2>{`Newsletter`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://digihub.de/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`digihub.de/`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://digitalhubcologne.de/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`digitalhubcologne.de`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.neuesunternehmertum.de/newsletter-anmeldung/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`neuesunternehmertum.de`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.startplatz.de/startplatz-newsletter-2/",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`startplatz.de`}</a></p>
      </li>
    </ul>
    <h2>{`Geschäftspläne / Business Plans`}</h2>
    <p><a parentName="p" {...{
        "href": "https://gruenderplattform.de/businessplan/businessplan-vorlage",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`KFW`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://www.existenzgruender.de/SharedDocs/Downloads/DE/GruenderZeiten/GruenderZeiten-07.pdf?__blob=publicationFile",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`BMWi`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.schule-fuer-unternehmer.de/Downloads/Schule-fur-Unternehmer-LeitfadenBusinessplan.pdf",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Schule für Unternehmer`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.neuesunternehmertum.de/wp-content/uploads/2018/09/NUK_Handbuch-2018_2019_Web1.pdf",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Neues Unternehmertum`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.gruenderkueche.de/wp-content/uploads/2019/10/GK-Checkliste-Businessplan-PDF-Download.pdf",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Gründerküche`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.hbs.edu/newventurecompetition/Documents/SE-TrackAppSuccess_BusPlan.pdf",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Havard Business School`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://3c-consulting.de/wp-content/uploads/2018/11/Businessplan-Muster-IHK-Siegen.pdf",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`IHK Siegen`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.gruendungswerkstatt-nrw.de/downloads",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Gründungswerkstatt NRW`}</a></p>
    <h2>{`Initiativen`}</h2>
    <p><strong parentName="p">{`CBS Entrepreneur Club/Founders Factory`}</strong>{` – studentische Gründerinitiative an der CBS International Business School
Der Entrepreneur Club ist eine Initiative der CBS, die es Studenten ermöglicht einen Einblick in die Selbstständigkeit zu erhalten. Durch Treffen, die 1x alle 2 Wochen stattfinden, vergrößern wir unser „Know-How“ in Bezug auf aktuelle Themen. Außerdem besprechen wir ausgewählte Bücher zu relevanten Themen.`}</p>
    <p><strong parentName="p">{`Enacuts Köln e.V.`}</strong>{` – internationales Studierenden-Netzwerk
Enactus ist ein internationales Studierenden-Netzwerk, das unternehmerische Projekte mit Nachhaltigkeit und sozialem Engagement verbindet. Entrepreneure, Start-Up-Interessierte sowie Studierende mit Engagement und Eigeninitiative, sind herzlich eingeladen, sich zu beteiligen.`}</p>
    <p><strong parentName="p">{`Entrepreneurs Club Cologne`}</strong>{` – studentische Gründerinitiative an der Uni Köln
Der Entrepreneurs Club Cologne wurde mit dem Ziel ins Leben gerufen, Studenten und jungen Absolventen von allen Hochschulen, die sich für das Thema Unternehmensgründung interessieren, eine Plattform zu bieten. Neben regelmäßigen Erfahrungsberichten von GründerInnen findet ein monatlicher Stammtisch statt.`}</p>
    <p><strong parentName="p">{`Entrepreneurs Club`}</strong>{` – Technische Hochschule Köln
Der neue Entrepreneurs Club der TH Köln hat das Ziel, gründungsinteressierten Studierenden, Unterstützung und Netzwerk-Möglichkeiten zu bieten.`}</p>
    <h2>{`Internetseiten`}</h2>
    <p><a parentName="p" {...{
        "href": "https://angel.co/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`AngelList`}</a>{` – Startup community mit Business Angels`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.deutsche-startups.de/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Deutsche-StartUps`}</a>{` – allles zur deutschen StartUp Szene und ihre GründerInnen`}</p>
    <p><a parentName="p" {...{
        "href": "https://entrepreneurs-club-cologne.de/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Entrepreneurs Clup Cologne`}</a>{` – Der Entrepreneurs Club Cologne ist eine unabhängige studentische Initiative, ein Verein für Gründungsinteressierte und Gründer sowie Schnittstelle zwischen Startup und Studium`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.existenzgruender-jungunternehmer.de/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Existenzgründer und Jungunternehmer`}</a>{` – diese Seite versteht sich vor allem als Einstigeshilfe in das Entrepreneurship`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.existenzgruender.de/DE/Home/inhalt.html",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Existenzgründungsportal des BMWi`}</a>{` – Das Existenzgründungsportal des Bundesministeriums für Wirtschafts und Technologie bietet umfassende Informationen zu Gründungsthemen und begleitet Gründungsinterssierte Schrifttweise in die Selbstständigkeit
Tipp: die einzelnen GründerZeiten könnt ihr Euch hier oder hier herunterladen`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.gruendungswerkstatt-nrw.de/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Gründungswerkstatt NRW`}</a>{` – Clever Gründen mit Expertenwissen`}</p>
    <p><a parentName="p" {...{
        "href": "https://gruenderplattform.de/businessplan/businessplan-vorlage",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Gründerplattform`}</a>{` – Das Bundeswirtschaftsministerium und die KfW sind Initiatoren und Begleiter der Plattform.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.fuer-gruender.de/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Für Gründer`}</a>{` – alles zu den Schlagworten Wissen, Kapital & Beratung`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.gruenderkueche.de/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Gründerküche`}</a>{` – Erfolgsrezepte für Startups und Unternehmen`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.theguardian.com/small-business-network/starting-a-new-business",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`The Guardian`}</a>{` – unter dem Stichwort Small Business Network berichten ExpertInnen, wie CEOs, zu verschiedenen Themen und Gründungsideen`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.gruenderlexikon.de/index.php?id=889&utm_expid=20817310-7.BXWbi6bwQcmrIruv9venOQ.1",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Gründerlexikon`}</a>{` – Schritt für Schritt zur Existenzgründung mit zahlreichen Tipps, News und Tools`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.gruenderszene.de/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Gründerszene`}</a>{` – eine der umfangreichsten und etabliertesten Websites rund ums Thema Entrepreneurship`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.junge-gruender.de/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Junge Gründer`}</a>{` – Zielgruppe hier sind vor allem junge StartUps und solche, die es werden wollen`}</p>
    <p><a parentName="p" {...{
        "href": "https://koeln.business/de/gruenden/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Köln Business`}</a>{` – Durchstarten in Köln`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.meinstartup.com/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`meinstartup`}</a>{` – Online-Magazin der ersten Stunde und nach wie vor ein guter Tipp`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.selbststaendig.de/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`selbstaendig.de`}</a>{` – Infos, Ideen und Tools`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.selbstaendig-im-netz.de/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Selbständig im Netz`}</a>{` – Ratgeber digitale StartUps`}</p>
    <p><a parentName="p" {...{
        "href": "https://start-green.net/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Start Green`}</a>{` – das Portal der Green Economy`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.starting-up.de/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`StartingUp`}</a>{` – verschiedenste Themenbereiche zum Thema Gründung werden abgedeckt. Vor allem das Gründungsnavi führt Euch Schritt für Schritt durch den Prozess – lohnt sich!`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.steuertipps.de/selbststaendig-freiberufler",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Steuertipps`}</a>{` – Hilfestellungen für Gründer und Selbstständige`}</p>
    <p><a parentName="p" {...{
        "href": "https://techcrunch.com/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Tech Crunch`}</a>{` – Startup and Technology News`}</p>
    <p><a parentName="p" {...{
        "href": "https://t3n.de/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`t3n`}</a>{` – der Blog des gleichnamigen Magazins ist vor allem für digitale StartUps und technikaffine GründerInnen geeignet`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.vc-magazin.de/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Venture Capital Magazin`}</a>{` – Das Portal für Investoren und Entrepreneure`}</p>
    <h2>{`E-Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.osborneclarke.com/media/filer_public/13/fe/13fefee2-aee6-4629-a109-0cf25a5147d0/getting-started-2013.pdf",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`getting started — aber richtig`}</a></p>
    <p>{`Der Dschungel zu beachtender rechtlicher Aspekte ist mitunter unüberschaubar – insbesondere für Existenzgründer. Aus diesem Grund hat die internationale Wirtschaftskanzlei Osborne Clarke für StartUp Unternehmen ein e-book geschrieben, das ständig aktualisiert und erweitert wird. Jungunternehmer können hiermit die ersten juristischen Hürden während des Aufbaus ihres Unternehmens ohne anwaltliche Hilfe erkennen und verstehen. Für StartUps typische Fehler sollen vermieden werden.
Das e-book kann `}<a parentName="p" {...{
        "href": "https://www.osborneclarke.com/media/filer_public/13/fe/13fefee2-aee6-4629-a109-0cf25a5147d0/getting-started-2013.pdf",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`hier`}</a>{` kostenlos heruntergeladen werden.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.existenzgruender-jungunternehmer.de/fileadmin/Ebooks/existenzgruendung-fuer-studenten.pdf",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Studieren, gründen, durchstarten`}</a></p>
    <p>{`Wenn man als Student Gründer wird, birgt dies andere Chancen und Herausforderungen, als für Berufstätige. Das e-Book bietet eine Orientierung für Studenten zu Themen wie Beratung, Finanzierung oder einem Gründer-ABC und stellt diese Interviews von Hochschulberatern und studentischen Gründern zur Seite.`}</p>
    <h2>{`Videos`}</h2>
    <p><a parentName="p" {...{
        "href": "https://innovationlabs.harvard.edu/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Harvard Innovation Lab`}</a>{` – Vorträge von der Hochschule rund ums Thema Entrepreneurship`}</p>
    <p><a parentName="p" {...{
        "href": "http://startupclass.samaltman.com/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Sam Altmann @Stanford`}</a>{` – die Universität leitet dich mit der Videoreihe „How to start a StartUp“ einmal durch den Gründungsprozess`}</p>
    <h2>{`Links`}</h2>
    <h2>{`Coaches und Berater`}</h2>
    <h2>{`Kommunikation & Design`}</h2>
    <ul>
      <li parentName="ul">{`Aretz & John, `}<a parentName="li" {...{
          "href": "https://www.aretzjohn.com/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`https://www.aretzjohn.com/`}</a></li>
    </ul>
    <h2>{`Workspaces`}</h2>
    <h2>{`Business Angels & VCs`}</h2>
    <h2>{`Dienstleister`}</h2>
    <h3>{`Rechtsanwälte`}</h3>
    <h3>{`Steuerberater`}</h3>
    <h3>{`Wirtschaftsprüfer`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      